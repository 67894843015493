export interface ContactFormDataProps {
  name: string;
  email: string;
  mobile: string;
  message: string;
}

export const validateContactForm = (contactFormData: ContactFormDataProps) => {
  const emailRegex = /^\S+@\S+\.\S+$/;
  const mobileRegex = /^[6-9]\d{9}$/;
  const error = {
    errorFound: {
      name: "",
      email: "",
      mobile: "",
      message: "",
    },
    isError: false,
  };

  if (contactFormData.name.trim().length < 1) {
    error.errorFound.name = "Please provide us fullname";
    error.isError = true;
  }

  if (!emailRegex.test(contactFormData.email.trim())) {
    error.errorFound.email = "Please provide us a valid email";
    error.isError = true;
  }

  if (!mobileRegex.test(contactFormData.mobile.trim())) {
    error.errorFound.mobile = "Please provide us valid mobile";
    error.isError = true;
  }

  if (contactFormData.message.trim().length < 1) {
    error.errorFound.message = "Please provide us message";
    error.isError = true;
  }

  return error;
};
