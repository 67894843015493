/* eslint-disable max-lines */
import { DIRECTION_VARIANTS } from "@libs/utils/animation";
import { v4 as uuidv4 } from "uuid";
import appStoreIos from "@book-the-play/public/img/svg/appStore.svg";
import googlePlayStore from "@book-the-play/public/img/svg/platStore.svg";
import instagram from "@book-the-play/public/img/svg/instagram.svg";
import linkedin from "@book-the-play/public/img/svg/linkedin.svg";
import youTube from "@book-the-play/public/img/svg/youTube.svg";
import { routes } from "./route";
import unified from "@book-the-play/public/img/venueFeatures/1unified.svg";
import effortless from "@book-the-play/public/img/venueFeatures/2effortless.svg";
import increase from "@book-the-play/public/img/venueFeatures/3increase.svg";
import courtscheduling from "@book-the-play/public/img/venueFeatures/4courtscheduling.svg";
import track from "@book-the-play/public/img/venueFeatures/5track.svg";
import realtime from "@book-the-play/public/img/venueFeatures/6realtime.svg";

// Image Width and Height
export const verySmallWidth = 14;
export const extraSmallWidth = 34;
export const minSmallWidth = 75;
export const smallWidth = 95;
export const minMediumWidth = 161;
export const mediumWidth = 270;
export const veryLargeWidth = 1290;

export const verySmallHeight = 14;
export const extraExtraSmallHeight = 23;
export const extraSmallHeight = 34;
export const minSmallHeight = 75;
export const mediumHeight = 270;
export const extraLargeHeight = 570;
export const veryLargeHeight = 1290;

export const imageQuality = 100;

export const iconWidth = 25;
export const iconHeight = 25;

//oembed Url Match
export const YoutubeVideoUrl =
  "https://www.youtube.com/watch?v=oQdriR0aVYU?rel=0";
export const oembedUrlMatch = /url="([^"]*)"/;
export const oembedUrlPattern = "watch?v=";
export const replacementOfOembedUrlPattern = "embed/";

// Key Feature List show limits
export const leftStartingPoint = 0;
export const leftEndingPoint = 4;
export const rightEndingPint = 8;

export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.webelight.booktheplay&hl=en-IN&pli=1";

export const appStoreUrl =
  "https://apps.apple.com/in/app/booktheplay/id6449187909";

export const footerSocialLinks = [
  {
    path: "https://www.youtube.com/channel/UCH4vFBp7klbyG4eBdO-1sUA",
    title: "Youtube",
    imagePath: youTube,
    imgAlt: "youTube",
    className: "wb-w-[25px] wb-h-[25px]",
  },
  {
    path: "https://www.instagram.com/book.the.play/",
    title: "Instagram",
    imagePath: instagram,
    imgAlt: "instagram",
    className: "wb-w-[25px] wb-h-[25px]",
  },
  {
    path: "https://www.linkedin.com/company/book-the-play/",
    title: "Linkedin",
    imagePath: linkedin,
    imgAlt: "linkedin",
    className: "wb-w-[15px] wb-h-[15px]",
  },
  {
    path: "https://play.google.com/store/apps/details?id=com.webelight.booktheplay&hl=en-IN&pli=1",
    title: "Play store",
    imagePath: googlePlayStore,
    imgAlt: "googlePlayStore",
    className: "wb-w-[20px] wb-h-[20px]",
  },
  {
    path: "https://apps.apple.com/in/app/booktheplay/id6449187909",
    title: "App store",
    imagePath: appStoreIos,
    imgAlt: "appStoreIos",
    className: "wb-w-[20px] wb-h-[20px]",
  },
];

export const MAP_API_RESPONSE = {
  SUCCESS: "OK",
};

//header links
export const headerMenu = [
  {
    id: 1,
    title: "Overview",
    path: routes.home,
  },
  {
    id: 2,
    title: "How It Works",
    path: routes.howItWorks,
  },
  {
    id: 3,
    title: "Key Features",
    path: routes.keyFeature,
  },
  {
    id: 4,
    title: "Become A Partner",
    path: routes.becomeAPartner,
  },
  {
    id: 5,
    title: "Downloads",
    path: routes.downloads,
  },
  {
    id: 6,
    title: "Contact Us",
    path: routes.contactUs,
  },
];

export const TAG_ICONS_LIMIT = 3;

export const bookThePlayImages = [
  {
    id: uuidv4(),
    path: "/img/BTP-moklups-mobile/splash-screen.jpg",
    alternateName: "splash-screen",
  },
  {
    id: uuidv4(),
    path: "/img/BTP-moklups-mobile/booking-history.jpg",
    alternateName: "booking-history",
  },
  {
    id: uuidv4(),
    path: "/img/BTP-moklups-mobile/slot-selection-screen.jpg",
    alternateName: "slot-selection-screen",
  },
  {
    id: uuidv4(),
    path: "/img/BTP-moklups-mobile/success-screen.jpg",
    alternateName: "success-screen",
  },
  {
    id: uuidv4(),
    path: "/img/BTP-moklups-mobile/summary-screen.jpg",
    alternateName: "summary-screen",
  },
];
export const bookThePlayStoreButton = [
  {
    id: uuidv4(),
    srcPath: "/img/demos/app-landing/icons/icon-google-play.svg",
    alternateName: "icon-google-play",
    storeName: "Play Store",
    socialLink: playStoreUrl,
    imageClassName: "wb-w-[40px]",
  },
  {
    id: uuidv4(),
    srcPath: "img/demos/app-landing/icons/icon-apple.svg",
    alternateName: "icon-apple",
    storeName: "App Store",
    socialLink: appStoreUrl,
    imageClassName: "wb-w-[30px]",
  },
];

export const keyFeaturesDetails = [
  {
    id: uuidv4(),
    title: "Discover diverse venues",
    description:
      "Browse and discover a variety of spots, including playgrounds, sports arenas, and recreational spaces.",
    imagePath: "img/demos/app-landing/icons/icon-monitor.svg",
    imgAlternateName: "icon-monitor",
    imgClassName: "wb-w-[100px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_LEFT,
    animationDelay: 0,
  },
  {
    id: uuidv4(),
    title: "Seamless Booking Process",
    description:
      "Ensures simplified user journey to make the booking process easy and efficient.",
    imagePath: "img/demos/app-landing/icons/icon-finger.svg",
    imgAlternateName: "icon-finger",
    imgClassName: "wb-w-[70px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_LEFT,
    animationDelay: 0.4,
  },
  {
    id: uuidv4(),
    title: "Cancellation/Refund Policy",
    description:
      "Clear communication of policies allow users to easily cancel bookings within a reasonable timeframe.",
    imagePath: "img/demos/app-landing/icons/icon-avatar.svg",
    imgAlternateName: "icon-avatar",
    imgClassName: "wb-w-[90px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_LEFT,
    animationDelay: 0.8,
  },
  {
    id: uuidv4(),
    title: "Secure Payment Integration",
    description:
      "Confirm your booking and make secure payments directly through the app.",
    imagePath: "img/demos/app-landing/icons/icon-paper-plane.svg",
    imgAlternateName: "icon-paper-plane",
    imgClassName: "wb-w-[60px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_LEFT,
    animationDelay: 1.2,
  },
  {
    id: uuidv4(),
    title: "Real-Time Booking",
    description:
      "Check real-time availability of venues and book specific dates and times directly through the platform.",
    imagePath: "img/demos/app-landing/icons/icon-refresh.svg",
    imgAlternateName: "icon-refresh",
    imgClassName: "wb-w-[100px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_RIGHT,
    animationDelay: 0.2,
  },
  {
    id: uuidv4(),
    title: "User Support",
    description:
      "Offers customer support via, email, or phone and also a comprehensive FAQ section.",
    imagePath: "img/demos/app-landing/icons/icon-chat.svg",
    imgAlternateName: "icon-chat",
    imgClassName: "wb-w-[80px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_RIGHT,
    animationDelay: 0.6,
  },
  {
    id: uuidv4(),
    title: "Handy Digital Wallets",
    description:
      "Users have the option to add funds to their wallet, view their current balance & make transactions within the app using their digital wallet.",
    imagePath: "img/demos/app-landing/icons/icon-pencil.svg",
    imgAlternateName: "icon-pencil",
    imgClassName: "wb-w-[110px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_RIGHT,
    animationDelay: 1,
  },
  {
    id: uuidv4(),
    title: "User-Friendly Interface",
    description:
      "An intuitive and easy-to-use interface for seamless navigation and booking.",
    imagePath: "img/demos/app-landing/icons/icon-star.svg",
    imgAlternateName: "icon-star",
    imgClassName: "wb-w-[60px]",
    blockAnimation: DIRECTION_VARIANTS.FROM_RIGHT,
    animationDelay: 1.4,
  },
];

export const frequentlyAskQuestionList = [
  {
    id: 1,
    title: "What is Book The Play?",
    description:
      "Book The Play is a comprehensive platform designed to help sports and fitness enthusiasts discover, book, and engage in various recreational activities and sports facilities.",
  },
  {
    id: 2,
    title: "How do I sign up?",
    description:
      "Welcome to the future of hassle-free onboarding. Simply scan with a QR Code and you’re are all set!",
  },
  {
    id: 3,
    title: "How can I find sports facilities, playgrounds or turfs near me?",
    description:
      "According to your preferred choice of sport, simply browse through a variety of sports facilities, select your preferred date and time, and follow the easy booking process to secure your reservation.",
  },
  {
    id: 4,
    title: "How secure is the payment process on Book The Play?",
    description:
      "Book The Play ensures a secure payment process using trusted payment gateways. Your financial information is handled with the utmost security.",
  },
  {
    id: 5,
    title: "What is Book The Play Wallet?",
    description:
      "Book The Play Wallet is a virtual wallet within the app platform that allows you to store funds securely for quick and hassle-free transactions.",
  },
  {
    id: 6,
    title: "How do I add funds to my Book The Play Wallet?",
    description:
      'You can add funds to your Wallet by selecting the "Wallet" section in the app, then choosing the "Add Funds" option. Follow the instructions to complete the transaction.',
  },
  {
    id: 7,
    title: "Are there any fees associated with using the Book The Play Wallet?",
    description:
      "Absolutely not! There are no fees associated with adding funds to your Book The Play Wallet.",
  },
  {
    id: 8,
    title: "Can I withdraw funds from my Book The Play Wallet?",
    description:
      "Book The Play Wallet funds are primarily intended for use within the platform. Thus you cannot withdraw or transfer funds. They can be used only for booking within the app.",
  },
];

export const staticSitemapSubRoutes = [
  "",
  "downloads",
  "privacy-policy",
  "refund-cancellation",
  "terms-conditions",
];

export const featureItems = [
  {
    id: 1,
    icon: unified,
    title: "Unified Platform for Multiple Courts",
    description:
      "Our app centralizes all your sports venues under one roof, allowing you to seamlessly oversee and control each court's schedule, bookings, and availability.",
  },
  {
    id: 2,
    icon: effortless,
    title: "Effortless Turf Management",
    description:
      "BookThePlay ensures simplified turf bookings, eliminating booking overlaps & optimizing your turf's availability as you effortlessly coordinate reservations.",
  },
  {
    id: 3,
    icon: increase,
    title: "Increase sales & outreach",
    description:
      "Enjoy increased reservations and maximize your venue's occupancy & bookings through our user-friendly platform.",
  },
  {
    id: 4,
    icon: courtscheduling,
    title: "Court scheduling & inventory management",
    description:
      "Manage court availability in real time to increase court utilization and reduce no-shows resulting in drastic reduction in operational overheads.",
  },
  {
    id: 5,
    icon: track,
    title: "Track analytics",
    description:
      "Access real-time data insights, analyze usage patterns, and optimize your operations with comprehensive reports, for efficient decision-making.",
  },
  {
    id: 6,
    icon: realtime,
    title: "Realtime alerts & notifications",
    description:
      "Track Bookings from all sources enable instant user & administrator alerts, send confirmation & cancellation messages, payment links and lots more.",
  },
];
