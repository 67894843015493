export const routes = {
  home: "/",
  downloadsPage: "/downloads",
  howItWorks: "#how-it-works",
  keyFeature: "#key-features",
  becomeAPartner: "#become-a-partner",
  downloads: "#downloads",
  contactUs: "#contact-us",
  privacyPolicy: "/privacy-policy",
  refundCancellation: "/refund-cancellation",
  termsCondition: "/terms-conditions",
  webelightWebSite: "https://webelight.co.in",
};
