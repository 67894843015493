import { ContactFormDataProps } from "./validate";

export const cleanContactForm = (data: ContactFormDataProps) => {
  const res: ContactFormDataProps = {
    name: "",
    email: "",
    mobile: "",
    message: "",
  };

  res.name = data.name.trim();
  res.email = data.email.trim();
  res.mobile = data.mobile.trim();
  res.message = data.message.trim();

  return res;
};
